.souscription-title {
    color: #243E45;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
}

.souscription-card {
    background-color: #f8f9fa;
    border-radius: 10px;
}

.souscription-info {
    background-color: #e9ecef;
    border-color: #243E45;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 1.5;
}

.souscription-subtitle {
    color: #243E45;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
}

.form-select {
    border-radius: 5px;
}

.entrance-fees-list {
    font-family: "Roboto", sans-serif;
}
.entrance-fee-item {
    cursor: pointer;
    transition: all 0.3s ease;
}

.entrance-fee-item:hover {
    background-color: #95C2B0;
    color: white;
}

