/* @font-face {
  font-family: 'GT-Super-Display-Regular';
  src: url('./police/GT-Super-Display-Regular.woff2') format('woff2'),
       url('./police/GT-Super-Display-Regular.woff') format('woff'),
       url('./police/Inter-Regular.ttf') format('truetype');
       font-weight: 400;
       font-display: swap;
       font-style: normal;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #243E45;
}

.body-custom-font {
  font-family: 'GT-Super-Display-Regular', sans-serif;
}

.body-custom-font code {
  font-family: 'GT-Super-Display-Regular', sans-serif;
}
 */

 @font-face {
  font-family: 'GT-Super-Display-Regular';
  src: url('./police/GT-Super-Display-Regular.woff2') format('woff2'),
       url('./police/GT-Super-Display-Regular.woff') format('woff');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Inter-Regular';
  src: url('./police/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

/* Utiliser Inter comme police par défaut */
body, .body-custom-font {
  font-family: 'Inter-Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Utiliser GT Super Display pour les titres */
h1, h2, h3, h4, h5, h6, .title-font {
  font-family: 'GT-Super-Display-Regular', serif;
}

/* Style spécifique pour le code, s'il doit être différent */
code {
  font-family: 'Inter-Regular', sans-serif;
}

/* Vos classes existantes */
.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}