/* @font-face {
  font-family: 'GT-Super-Display-Regular';
  src: url('./police/GT-Super-Display-Regular.woff2') format('woff2'),
       url('./police/GT-Super-Display-Regular.woff') format('woff'),
       url('./police/Inter-Regular.ttf') format('truetype');
       font-weight: 400;
       font-display: swap;
       font-style: normal;
}

body {
  margin: 0;
  font-family: 'GT-Super-Display-Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'GT-Super-Display-Regular', sans-serif;
} */


@font-face {
  font-family: 'GT-Super-Display-Regular';
  src: url('./police/GT-Super-Display-Regular.woff2') format('woff2'),
       url('./police/GT-Super-Display-Regular.woff') format('woff');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Inter-Regular';
  src: url('./police/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

/* Appliquer Inter comme police par défaut */
body {
  margin: 0;
  font-family: 'Inter-Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Utiliser GT Super Display pour les titres */
h1, h2, h3, h4, h5, h6 {
  font-family: 'GT-Super-Display-Regular', serif;
  font-weight: 400;
}

/* Ajustement pour les éléments de code (si nécessaire) */
code {
  font-family: 'Inter-Regular', sans-serif;
}
