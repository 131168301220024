.btn-custom {
    color: #ffffff !important;
    background-color: #243E45 !important;
    border: none !important;
    transition: background-color 0.3s !important; 
}


.font-weight-bold {
    font-weight: bold !important;
}
