.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 13rem;
    background-color: #243E45;
    padding: 2rem 1rem;
    overflow-y: auto;
    transition: width 0.3s;
}

.sidebar.closed {
    width: 0;
    overflow: hidden;
}

.sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sidebar-header button {
    background: none;
    border: none;
    cursor: pointer;
    display: none;
}

.sidebar-brand {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
    color: #343a40;
}

.sidebar-content {
    margin-top: 1rem;
}

.sidebar-nav .nav-link {
    font-weight: 500;
    color: #ffffff;
}

.sidebar-nav .nav-link:hover {
    color: #95C2B0 !important;
}

.navbar-separator {
    height: 1px;
    background-color: #ffffff;
    margin: 8px 0;
}

.small-logo {
    max-width: 50%;
}

.large-logo {
    max-width: 100%;
}
.profile-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.profile-section p {
    margin-top: 10px;
}

.user-options {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 60px;
}

.logout-section {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 60px;
}


.navbar-mobile {
    background-color: #243E45; 
}

.navbar-mobile .nav-link {
    color: #ffffff; 
}

.navbar-mobile .nav-link:hover {
    color: #95C2B0;
}



/* Hidden on small devices */
@media (max-width: 991px) {
    .sidebar {
        display: none;
    }
}

/* Visible on large devices */
@media (min-width: 992px) {
    .sidebar {
        display: block;
    }
}
