.mes-souscriptions {
    display: flex;
    flex-direction: column;
}

.search-input-container {
    position: relative;
    margin-bottom: 1rem;
}

.search-input-container input {
    padding-right: 2.5rem;
}

.search-icon {
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
    color: #757575;
    font-size: 1.2rem;
    pointer-events: none;
}

.btn-custom {
    color: #ffffff !important;
    background-color: #243E45 !important;
    border: none !important;
    transition: background-color 0.3s !important; 
}
 .btn-custom:hover {
    background-color: #95C2B0 !important;
}

.dropdown-item:active {
    background-color: #95C2B0 !important;
}

.smaller-table-container {
    font-size: 12px; /* Réduire la taille de police */
}
