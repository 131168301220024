.choice-box {
    padding: 5px 10px;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    margin-left: 10px;
}

.choice-box-green {
    background-color: green;
}

.choice-box-red {
    background-color: red;
}
