.title {
    color: #243E45;
    font-size: 2.5rem;
    text-align: center;
    margin: 2rem 0;
}

.cardContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-gap: 1rem;
    margin-bottom: 2rem;
}

.btn-custom {
    color: #ffffff !important;
    background-color: #243E45 !important;
    border: none !important;
    transition: background-color 0.3s !important; 
}
 .btn-custom:hover {
    background-color: #95C2B0 !important;
}
