/* Remplace la couleur de fond des boutons d'accordéon */
.accordion-button {
    background-color: #f8f9fa !important; /* Couleur personnalisée */
    border: 1px solid !important; 
    color: #000 !important; /* Couleur du texte */
}

/* État fermé de l'accordéon */
.accordion-button:not(.collapsed) {
    background-color: #f8f9fa !important; /* Couleur personnalisée */
    color: #000 !important; /* Couleur du texte */
    border: 1px solid !important; 
}
