.produit-ag-container {
    padding: 20px;
}

.page-title {
    color: #243E45;
    margin-bottom: 30px;
}

.info-card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.info-column {
    margin-bottom: 15px;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
