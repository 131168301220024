.card {
    border: 1px solid #092A6C;
    border-radius: 0.5rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s;
}

.card:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.cardHeader {
    background-color: #224A96;
    color: #ffffff;
    font-weight: bold;
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem 0.5rem 0 0;
    display: flex;
    justify-content: space-between;
}

.cardBody {
    display: none;
    padding: 1rem;
}

.cardBody.show {
    display: block;
}

.table {
    margin-bottom: 0;
}

.actionButton {
    font-size: 0.9rem;
    padding: 0.25rem 0.5rem;
}

.fullWidth {
    width: 100%;
}
