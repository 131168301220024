.input-group .form-control[type="file"] {
    padding: 6px 12px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btn-custom {
    color: #ffffff !important;
    background-color: #243E45 !important;
    border: none !important;
    transition: background-color 0.3s !important; 
}
 .btn-custom:hover {
    background-color: #95C2B0 !important;
}
