.max-width-100 {
    width: 700px;
    display: block;
    margin: auto;

}

.btn-custom {
    color: #ffffff !important;
    background-color: #243E45 !important;
    border: none !important;
    transition: background-color 0.3s !important; 
}
 .btn-custom:hover {
    background-color: #95C2B0 !important;
}

.doc-name {
    text-align: left;
}



