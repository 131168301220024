/* Footer.css */
.footer {
    background-color: #ffffff;
    font-family: 'CabinetGrotesk-Regular', sans-serif;
    padding: 10px 0;
}

.footer-links h5 {
    margin-bottom: 20px;
    color: #333; /* Changez la couleur du texte des liens */
}

.footer-links ul li {
    margin-bottom: 10px;
}

.footer-links a {
    color: #333; /* Changez la couleur des liens */
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-links a:hover {
    color: #95C2B0; /* Changez la couleur au survol */
}

.footer-copy p {
    margin-top: 10px;
    color: #555; /* Changez la couleur du texte du pied de page */
    font-size: 14px;
    text-align: center;
}
