.card-title {
    color: #243E45;
;
}

.article-item {
    color: #243E45;
    margin-bottom: 20px;
}

.reporting-item {
    margin-bottom: 20px;
    color: #243E45;
}


.container-map {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: 10%; /* Ajuster la valeur selon vos besoins */
}

.container-map h2 {
    color: #243E45;
    font-size: 3rem;
}

.container-map h2 span {
    font-size: 5rem;
    font-weight: bold;
}

.map-frame {
    height: 600px;
    width: 1300px;
}
